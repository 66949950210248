import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Adam 👋`}</h1>
    <p>{`I’ve had a website here for about 15 years. At times it was a blog, at times it had lots of content, but for now, this is just a little info about me and some links.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      