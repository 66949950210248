import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Learning 🧐`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I like learning. I have lots to learn.`}</p>
    </blockquote>
    <p>{`I studied Fine Art at `}<a parentName="p" {...{
        "href": "https://www.uca.ac.uk/"
      }}>{`UCA`}</a>{`, and then did a degree in Computer Science & Creative writing with the `}<a parentName="p" {...{
        "href": "https://www.open.ac.uk/"
      }}>{`OU`}</a>{` while I was working.`}</p>
    <p>{`For the last few years, I’ve been learning music production, recording, mixing and mastering.`}</p>
    <p>{`If I’m not writing code at work, I’ll keep my hand in with projects of my own. I’m usually bouncing around between a variety of books and audio books to keep exploring new ideas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      