/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui"

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  
  setColorMode(`dark`)
  // const isDark = colorMode === `dark`
  // const toggleColorMode = (e: any) => {
  //   setColorMode(isDark ? `light` : `dark`)
  // }

  return (
    <Box as="footer" variant="footer">
      {/* <button
        sx={{ variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3 }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        {isDark ? `Light` : `Dark`}
      </button> */}
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
      <br />
      <Flex
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
        }}
      >
        
        {` `}
        <Link
          aria-label="Adam Lofting Github"
          sx={{ ml: 1 }}
          href="https://github.com/adamlofting"
        >
          Github
        </Link>
        
        {` `}
        <Link
          aria-label="Adam Lofting LinkedIn"
          sx={{ ml: 1 }}
          href="https://www.linkedin.com/in/adamlofting/"
        >
          LinkedIn
        </Link>
      </Flex>
    </Box>
  )
}

export default Footer
