import * as React from "react"
import { Parallax } from "@react-spring/parallax"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Work from "../components/work"
import Play from "../components/play"
import Contact from "../components/contact"
import Learning from "../components/learning"
import Photos from "../components/photos"

const Cara = () => (
  <Layout>
    <Parallax pages={8}>
      <Hero offset={0} factor={1} />
      <About offset={1} factor={1} />
      <Photos offset={2} factor={1} />
      <Work offset={3} factor={2} />
      <Play offset={5} factor={1} />
      <Learning offset={6} factor={1} />
      <Contact offset={7} factor={1} />
    </Parallax>
  </Layout>
)

export default Cara