import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LogoCard = makeShortcode("LogoCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <LogoCard img="/logos/rocksteady.jpg" title="Rocksteady Music School" link="https://rocksteadymusicschool.com/" mdxType="LogoCard" />
    <LogoCard img="/logos/sega_two_point.jpg" title="SEGA / Two Point Studios" link="https://www.twopointstudios.com/" mdxType="LogoCard" />
    <LogoCard img="/logos/kyan.jpg" title="Kyan" link="https://kyan.com/" mdxType="LogoCard" />
    <LogoCard img="/logos/mozilla_firefox.jpg" title="Mozilla Foundation" link="https://mozilla.org/" mdxType="LogoCard" />
    <LogoCard img="/logos/wwf.jpg" title="WWF" link="https://wwf.org/" mdxType="LogoCard" />
    <LogoCard img="/logos/flipside.jpg" title="Flipside Group" link="https://flipsidegroup.com/" mdxType="LogoCard" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      