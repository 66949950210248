import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Play things 🕹`}</h2>
    <p>{`When I’m not working, I design and develop games (video games and board games). In the past, I’ve published a few things under the name `}<a parentName="p" {...{
        "href": "https://alphadeltagames.com"
      }}>{`Alpha Delta Games`}</a>{`.`}</p>
    <p>{`You’ll also find me making music with my dad-band friends in `}<a parentName="p" {...{
        "href": "https://shoesonnow.com"
      }}>{`Shoes on, Now!`}</a>{` and sometimes playing bass in a Green Day tribute band or tinkering with synths making electronic music.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      