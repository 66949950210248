import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`I like making things 💡`}</h2>
    <blockquote>
      <p parentName="blockquote">{`For work, that’s software. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`For fun, that's games, music, art and sometimes real-world physical things. `}</p>
    </blockquote>
    <p>{`On the first version of this website all those years ago, I said something like “I like creating, and solving problems”. Sometimes I would skip the comma after the word ‘creating’. That’s still true today.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      